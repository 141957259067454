@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,400;0,700;1,400&family=Open+Sans:ital,wght@0,400;0,700;1,400&family=Oswald:wght@400;700&family=Roboto:ital,wght@0,400;0,700;1,400&family=Special+Elite&family=Ubuntu:ital,wght@0,400;0,700;1,400&display=swap');

:root {
    --bgColor: #ffffff;
    --textColor: #222222;
    --fontFamily: "Arial";
    --markupTextColor: #d43c3c;
    --markupBgColor: #ffffff;
}

.editorjs-box {
    background: var(--bgColor);
    color: var(--textColor);
    font-family: var(--fontFamily);
}

.cdx-marker {
    background: var(--markupBgColor) !important;
    color: var(--markupTextColor) !important;
}

/* React Select */

.react-select__control {
    width: 100% !important;
    min-width: 0px!important;
    outline: 2px solid transparent!important;
    outline-offset: 2px!important;
    font-size: var(--chakra-fontSizes-md)!important;
    height: var(--chakra-sizes-10)!important;
}

.react-select__control--is-focused, .react-select__control--menu-is-open {
    border-color: var(--chakra-colors-brand-500) !important;
    box-shadow: 0px 0px 0px 1px var(--chakra-colors-brand-500) !important;
}

.react-select__indicator-separator {
    display: none !important;
}

.chakra-ui-dark .react-select__indicator {
    color: var(--chakra-colors-gray-400) !important;
}

.chakra-ui-light .react-select__indicator {
     color: var(--chakra-colors-gray-800) !important;
 }
